import WalletConnectProvider from "@walletconnect/web3-provider";
//import Torus from "@toruslabs/torus-embed"
import WalletLink from "walletlink";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import Web3Modal from "web3modal";
import { INFURA_ID, NETWORKS } from "./constants";
import { Transactor } from "./helpers";
import { useBalance, useContractLoader, useContractReader, useGasPrice, useUserProviderAndSigner } from "eth-hooks";
// import { useExchangeEthPrice } from "eth-hooks/dapps/dex";

import { useContractConfig } from "./hooks";
import Portis from "@portis/web3";
import Fortmatic from "fortmatic";
import Authereum from "authereum";
import { useLocation, BrowserRouter, Link, Route, Switch } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Home, Wallet, Claims } from "./components";
import Address from "./components/Address";

const { ethers } = require("ethers");

let pollTime = 180000;
/*
document.addEventListener("visibilitychange", () => {
  console.log("visibilityState", document.visibilityState);
  if (document.visibilityState === "visible") {
    pollTime = 60000;
  } else if (document.visibilityState === "hidden") {
    pollTime = 3600000;
  }
});
*/
/*
    Welcome to 🏗 scaffold-eth !

    Code:
    https://github.com/austintgriffith/scaffold-eth

    Support:
    https://t.me/joinchat/KByvmRe5wkR-8F_zz6AjpA
    or DM @austingriffith on twitter or telegram

    You should get your own Infura.io ID and put it in `constants.js`
    (this is your connection to the main Ethereum network for ENS etc.)


    🌏 EXTERNAL CONTRACTS:
    You can also bring in contract artifacts in `constants.js`
    (and then use the `useExternalContractLoader()` hook!)
*/

/// 📡 What chain are your contracts deployed to?
const targetNetwork = NETWORKS.mainnetAvalanche; // <------- select your target frontend network (localhost, rinkeby, xdai, mainnet)

// 😬 Sorry for all the console logging
const DEBUG = false;

// 🏠 Your local provider is usually pointed at your local blockchain
const localProviderUrl = targetNetwork.rpcUrl;
// as you deploy to other networks you can set REACT_APP_PROVIDER=https://dai.poa.network in packages/react-app/.env
const localProviderUrlFromEnv = process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : localProviderUrl;
if (DEBUG) console.log("🏠 Connecting to provider:", localProviderUrlFromEnv);
const localProvider = new ethers.providers.StaticJsonRpcProvider(localProviderUrlFromEnv);

// 🔭 block explorer URL
const blockExplorer = targetNetwork.blockExplorer;

// Coinbase walletLink init
const walletLink = new WalletLink({
  appName: "coinbase",
});

// WalletLink provider
const walletLinkProvider = walletLink.makeWeb3Provider(`https://mainnet.infura.io/v3/${INFURA_ID}`, 1);

// Portis ID: 6255fb2b-58c8-433b-a2c9-62098c05ddc9
/*
  Web3 modal helps us "connect" external wallets:
*/
const web3Modal = new Web3Modal({
  network: "mainnet", // Optional. If using WalletConnect on xDai, change network to "xdai" and add RPC info below for xDai chain.
  cacheProvider: true, // optional
  theme: "dark", // optional. Change to "dark" for a dark theme.
  providerOptions: {},
});

function App() {
  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  const gasPrice = targetNetwork !== NETWORKS.localhost ? useGasPrice(targetNetwork, "fast") : 0;

  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider); //, localProvider); don't use localProvider to create burner wallet.
  const userSigner = userProviderAndSigner.signer;

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
  }, [userSigner]);

  // You can warn the user if you would like them to be on a specific network
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;

  // For more hooks, check out 🔗eth-hooks at: https://www.npmjs.com/package/eth-hooks

  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userSigner, gasPrice);

  const contractConfig = useContractConfig();

  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(localProvider, contractConfig);

  // If you want to make 🔐 write transactions to your contracts, use the userSigner:
  const writeContracts = useContractLoader(userSigner, contractConfig, localChainId);
  // console.log("🔐 writeContracts", writeContracts);

  // const yourLocalBalance = useBalance(localProvider, address, pollTime);
  // const balance = useContractReader(readContracts, "TripsNFT", "balanceOf", [address], pollTime);
  // const priceToMint = useContractReader(readContracts, "HaktDAO", "currentPrice", null, pollTime);
  // const totalSupply = useContractReader(readContracts, "HaktDAO", "totalSupply", null, pollTime);

  //
  // 🧫 DEBUG 👨🏻‍🔬
  //
  // useEffect(() => {
  //   if (DEBUG && address && selectedChainId && yourLocalBalance && readContracts && writeContracts) {
  //     console.log("_____________________________________ 🏗 scaffold-eth _____________________________________");
  //     console.log("🏠 localChainId", localChainId);
  //     console.log("👩‍💼 selected address:", address);
  //     console.log("🕵🏻‍♂️ selectedChainId:", selectedChainId);
  //     console.log("🔐 yourLocalBalance", yourLocalBalance ? ethers.utils.formatEther(yourLocalBalance) : "...");
  //     console.log("📝 readContracts", readContracts);
  //     console.log("🔐 writeContracts", writeContracts);
  //     console.log("🔐 balance", balance ? ethers.utils.formatEther(balance) : "...");
  //   }
  // }, [address, selectedChainId, readContracts, writeContracts, balance]);

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", () => {
      console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  const [route, setRoute] = useState();
  useEffect(() => {
    setRoute(window.location.pathname);
  }, [setRoute]);

  const location = useLocation();

  return (
    <Suspense fallback={<h2>Loading from IPFS...</h2>}>
      <div>
        {/* NAVIGATION */}
        <div className="container-fluid">
          {/* <div className="mt-3 mr-3">
            <a className="navbar-brand" href="#">
              <img className="nav-logo" src="images/rabbithole-logo.png" alt="" />
            </a>
          </div> */}
          <nav className="navbar navbar-expand-md navbar-dark">
            <a className="navbar-brand" href="/">
              <img className="nav-logo" src="images/rabbithole-logo.png" alt="Rabbit Hole Logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
              <div className="w-100 d-flex justify-content-center justify-content-md-end">
                <div>
                  <ul className="navbar-nav mr-auto mt-2 mt-lg-0 d-flex align-items-center">
                    <li className="nav-item active">
                      <Link
                        className="nav-link"
                        onClick={() => {
                          setRoute("/wallet");
                        }}
                        to="/wallet"
                      >
                        My Wallet
                      </Link>
                    </li>
                    <li className="nav-item active">
                      <Link
                        className="nav-link"
                        onClick={() => {
                          setRoute("/claims");
                        }}
                        to="/claims"
                      >
                        Claims
                      </Link>
                    </li>
                    <li className="nav-item active ">
                      <a
                        className="nav-link"
                        href="https://nftrade.com/assets/avalanche/0xc59d0148895fe4e46b2baa5f709a6701958f6be6"
                        target="_blank"
                      >
                        NFT Trade
                      </a>
                    </li>
                    <li>
                      {web3Modal && web3Modal.cachedProvider ? (
                        <div className="wallet-connect d-flex bg-dark">
                          <Address address={address} size="short" ensProvider={localProvider} />
                          <a className="btn btn-sm btn-secondary text-dark" onClick={logoutOfWeb3Modal}>
                            Disconnect
                          </a>
                        </div>
                      ) : (
                        <a className="btn btn-secondary text-dark mx-4 px-2 py-1" onClick={loadWeb3Modal}>
                          Connect MetaMask
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/wallet">
            <Wallet readContracts={readContracts} address={address} />
          </Route>
          <Route exact path="/claims">
            <Claims tx={tx} readContracts={readContracts} address={address} writeContracts={writeContracts} />
          </Route>
        </Switch>

        <div className="footer text-center">
          <img className="nav-logo" src="images/rabbithole-logo.png" alt="" />
          <div className="d-flex flex-row mt-4 justify-content-center">
            <a href="https://twitter.com/rabbit_hole_nft" target="_blank">
              <img className="mx-2" src="images/home/twitter.svg" alt="twitter" />
            </a>
            <a href="https://discord.gg/2Gg27jh9qZ" target="_blank">
              <img className="mx-2" src="images/home/discord.svg" alt="discord" />
            </a>
          </div>
          <div className="mt-4">
            <p className="mb-0">TRIPs Contract:</p>
            <code className="text-secondary">0xc59d0148895fe4e46b2baa5f709a6701958f6be6</code>
          </div>
        </div>

        <Toaster
          position="bottom-right"
          toastOptions={{
            duration: 60000,
            className: "",
            style: {
              maxWidth: "500px",
            },
          }}
        />
      </div>
    </Suspense>
  );
}

export default App;
